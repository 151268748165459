/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  timThumpUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  callImage,
  showLoader,
  hideLoader,
  showCustomAlert,
  showCartLst,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";
import smartPhone from "../../common/images/smart-phone.png";
import proVouchar from "../../common/images/pro-vouchar.png";
import cashVouchar from "../../common/images/cash-vouchar.png";
import wishlist from "../../common/images/wishlisht.png";

import { GET_PRODUCT_LIST, GET_CART_DETAIL} from "../../actions";
var Parser = require("html-react-parser");
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      productDetails: [],
      filter_Tag: [],
      voucherProductDetail: [],
      voucher_product_qty: "",
      voucher_name: "",
      voucher_mobile: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
      error_voucher_mobile: "",
      voucherIndexFlag: "",
      product_List: [],
      imagesource: "",
      produdctMaxQuantity: '', 
      cartlistdetail: [],
      cartItems:[],
    };
  }

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    if (selectedNavigation !== this.state.selectedNavigation) {
      if (selectedNavigation !== "") {
        var catSlug = selectedNavigation;
        var subcatSlug = "";
        if (slugType === "subcategory") {
          catSlug = "";
          subcatSlug = selectedNavigation;
        }

        this.getCateProductList(nextProps.productState.navigateMenu);
        // this.props.getProductList(catSlug, subcatSlug, "");
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        selectedslugType: slugType,
      });
    }
    if (nextProps.productState.setFiltetTag === "Yes") {
      this.setState(
        { filter_Tag: nextProps.productState.filterTag },
        function () {
          this.productsubcatlist();
          this.props.sateValChange("setFiltetTag", "No");
        }
      );
    }

    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist },
        function () {
          this.productsubcatlist();
        }
      );
    }

  }

  componentDidMount(){

    $(".input-focus").focus(function () {
      $(this).parents(".focus-out").addClass("focused");
    });
    $(".input-focus").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this).parents(".focus-out").removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });

  }

  getCateProductList(navigateMenu) {
    if (navigateMenu !== "" && navigateMenu.length > 0) {
      var updateproductList = [];
      var current = this;
      navigateMenu.map(function (item) {
        var availabilityId =
          cookie.load("defaultAvilablityId") === undefined ||
          cookie.load("defaultAvilablityId") == ""
            ? deliveryId
            : cookie.load("defaultAvilablityId");

        var deliveryDate = '';
        var deliveryTime = (cookie.load("deliveryTime") !== undefined)?cookie.load("deliveryTime"):'';
        var deliveryDateTxt = (cookie.load("deliveryDate") !== undefined)?cookie.load("deliveryDate"):'';

        if(deliveryDateTxt != '') {
          var deliveryDateArr = deliveryDateTxt.split("/");
              deliveryDate = deliveryDateArr[2]+"-"+deliveryDateArr[1]+"-"+deliveryDateArr[0];
        } 
          
        var favCustId = (cookie.load("UserId") === undefined || cookie.load("UserId") == '' )?'':'&fav_cust_id='+cookie.load("UserId");
      
        Axios.get(
          apiUrlV2 +
            "products/getAllProducts?app_id=" +
            appId +
            "&availability=" +
            availabilityId +
            "&category_slug=" +
            item.pro_cate_slug +
            "&outletId=" +
            cookie.load("orderOutletId") +
            "&status=A"+
            favCustId + 
            "&orderdate=" +
            deliveryDate + 
            "&ordertime=" + deliveryTime

        ).then(function (response) {
            if (response.data.status === "ok") {
              if (current.state.imagesource === "") {
                current.setState({ imagesource: response.data.common });
              }
              var result = response.data.result_set;
              updateproductList[item.pro_cate_slug] = result;
              //if (navigateMenu.length - 1 === index) {
              current.productsubcatlist(updateproductList, navigateMenu);
              // }
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }

  productsubcatlist(updateproductList, navigateMenu) {
    let current = this;

    if (navigateMenu !== "" && navigateMenu !== undefined) {
      this.setState({
        OldupdateproductList: updateproductList,
        oldnavigateMenu: navigateMenu,
      });
      if (navigateMenu !== "" && navigateMenu.length > 0) {
        var product_List = navigateMenu.map(function (item, index) {
          return (
            <div id={item.pro_cate_slug} key={index}>
              <div
                className="product-category-list"
                key={index}
                id={"pro_cat_" + item.pro_cate_slug}
              >
                <h2>{stripslashes(item.pro_cate_name)}</h2>
                {item.pro_cate_short_description !== "" && (
                  <p>{stripslashes(item.pro_cate_short_description)}</p>
                )}
                {updateproductList[item.pro_cate_slug] !== "" &&
                updateproductList[item.pro_cate_slug] !== undefined
                  ? updateproductList[item.pro_cate_slug][0].subcategorie.map(
                      function (categories, sindex) {
                        return (
                          <div className="innerproduct" key={sindex}>
                            {/*  <h3>{stripslashes(categories.pro_subcate_name)}</h3> */}
                            <ul className="products-list-ulmain">
                              {current.productList(
                                categories.products,
                                categories.products.length
                              )}
                            </ul>
                          </div>
                        );
                      }
                    )
                  : ""}
              </div>
            </div>
          );
        });
        this.setState({ product_List: product_List }, function () {
          hideLoader("productlist-main-div", "class");
        });
      }
    }
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  /* Products List */
  productList(list, productcount) {
    var imageSource = this.state.imagesource.product_image_source;
    var tagImageSource = this.state.imagesource.tag_image_source;
    var slugType = this.state.selectedslugType;
    var naviSlug = this.state.selectedNavigation;
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      let filter = this.checkFilterTag(productDetail.product_tag);
      if (filter === 1) {
        return (
          <li
            className={
              this.chkProStockCls(
                productDetail.product_slug,
                productDetail.product_stock
              ) +
              (productDetail.product_type === "5"
                ? " voucher-product-box "
                : " ")
            }
            id={prodivId}
            key={index}
          >
            <div className="products-image-div">
              <a className="disbl_href_action favour-icon-link">
                <img className="disbl_href_action favour-heartwhite-icon" src={wishlist} />
              </a>
              {productDetail.product_tag_info !== "" &&
                productDetail.product_tag_info !== null && (
                  <div className="ribbon">
                    <span>{productDetail.product_tag_info}</span>
                  </div>
                )}
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : productDetail.product_type !== "5" ? (
                <img src={noimage} />
              ) : productDetail.product_voucher === "f" ? (
                <img src={proVouchar} />
              ) : (
                <img src={cashVouchar} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h5>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h5>
                </div>
                <div className="product-tag-list">
                  {Object.keys(productDetail.product_tag).length > 0 ? (
                    <ul>
                      {productDetail.product_tag.map((producttag, index1) => {
                        return (
                          <li key={index1}>
                            {stripslashes(producttag.pro_tag_name)}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="product-short-description">
                <p>{description !== "" ? description : Parser("")}</p>
              </div>
              <div className="product-price">
                <h3>S{Parser(showPriceValue(productDetail.product_price))}</h3>
              </div>
              <div className="products-ordernow-action">
                <div className="products-ordernow-inner">
                  {productDetail.product_stock > 0 ||
                  productDetail.product_stock === null ? (
                    productDetail.product_type === "1" ||
                    productDetail.product_type === "5" ? (
                      <a
                        className="button order_nowdiv smiple_product_lk disbl_href_action"
                        href="/"
                        title="Add to cart"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "initial"
                        )}
                      >
                        Add to cart
                      </a>
                    ) : (
                      <a
                        href="/"
                        onClick={this.viewProDetail.bind(this, productDetail)}
                        title="Order Now"
                        id={comboProId}
                        className="button order_nowdiv compo_product_lk"
                      >
                        Order Now
                      </a>
                    )
                  ) : (
                    <a
                      className="button order_nowdiv disabled disbl_href_action"
                      href="/"
                    >
                      Sold Out
                    </a>
                  )}

                  <div className="addcart_row addcart_done_maindiv">
                    <div className="addcart-row-child">
                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "decr",
                            productDetail
                          )}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value="1"
                        />
                        <span
                          className="qty_plus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "incr",
                            productDetail,

                          )}
                        >
                          +
                        </span>
                      </div>
                      <button
                        className="button btn_black"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "done"
                        )}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      }
    });

    return listProduct;
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }

  proQtyAction(indxFlg, actionFlg, productDetail) {

    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();

    var existValue = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .attr("data-prevqty");
    existValue =
      existValue !== "" &&
      typeof existValue !== undefined &&
      typeof existValue !== "undefined"
        ? existValue
        : 1;

    proqtyInput = parseInt(proqtyInput);
    var minQty = parseInt(productDetail.product_minimum_quantity);
    var maxQty = parseInt(productDetail.product_maximum_quantity);   
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
      this.props.getCartDetail();
    }

    var checkCartQty = this.checkCartQty(productDetail.product_id);
    var totalQtys = parseInt(checkCartQty) + parseInt(proqtyInput);

    if (actionFlg === "incr") {
      if (maxQty > 0) {
        if (maxQty < totalQtys) {
            var ErrorMsgMax = "Maximum selection for this product has exceeded";
            this.handleShowAlertFun(
              "Error", ErrorMsgMax);
             totalQtys = "";
          return false;
        }
      }
    }


    if (actionFlg === "decr") {
      if (minQty > 0) {
        if (minQty > totalQtys) {
          return false;
        }
      }
    }

    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);

    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .attr("data-prevqty", proqtyInput);

    /* if (actionFlg === "incr") {
      if (proqtyInput > 0) {
        if(minmaxqty > 0){
        if (proqtyInput > minmaxqty) {
          return false;
        }  
        } 
      }
    } 

    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);*/
  }


  checkCartQty(productID) {
    var totalQty = 0;
    var cartItem = this.props.productState.cartItem;

    if (cartItem.length > 0) {
      cartItem.map(function (item) {
        if (
          parseInt(item.cart_item_qty) > 0 &&
          productID === item.cart_item_product_id
        ) {
          totalQty = parseInt(totalQty) + parseInt(item.cart_item_qty);
        }
      });
    }
     return totalQty;
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    if (productDetail.product_type === "5") {
      var IndexFlg = productDetail.product_primary_id;
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });

      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });
    } else {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        return false;
      } else {

       var proqtyQty =$("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var maxQty = parseInt(productDetail.product_maximum_quantity);
        this.props.getCartDetail();
        var checkCartQty = this.checkCartQty(productDetail.product_id);
        var totalQtys = parseInt(checkCartQty) + parseInt(proqtyQty);

        if (maxQty > 0) {
          if (maxQty < totalQtys) {
            var ErrorMsgMax = "Maximum selection for this product has exceeded";
            this.handleShowAlertFun(
              "Error", ErrorMsgMax);
            $("#proIndex-" + IndexFlg).find(".proqty_input").val("1");
            $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
            $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
            return false;
          }

        }

         if (isNaN(totalQtys) === true) {
            proqtyQty = "";
          }

          $("#proIndex-" + IndexFlg)
            .find(".proqty_input")
            .val(proqtyQty);
          $("#proIndex-" + IndexFlg)
            .find(".proqty_input")
            .attr("data-prevqty", proqtyQty);
        

        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg).find(".proqty_input").val("1");
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            totalQtys = "";
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            this.handleShowAlertFun("Error", errMsgtxt);
          }

          return false;
        });
      }
    }
  }

  addToCartVoucherMe() {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/
    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;
    var postObject = {};
    postObject = {
      app_id: appId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_type: 5,
      availability_id: availabilityId,
      availability_name: availabilityName,
      isAddonProduct: isAddonProduct,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      voucher_for: "Me",
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();
      if (res.data.status === "ok") {
        this.props.sateValChange("cartflg", "yes");
        removePromoCkValue();
        /*showCartLst();*/
        this.handleShowAlertFun(
          "success",
          "Great choice! Item added to your cart."
        );
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">Please fill out this field.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">Please fill out this field.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">Please fill out this field.</span>'
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This is not a valid email</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">Please fill out this field.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.state.productimagePath +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        postImagePath = "";
      }

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_image: postImagePath,
        product_type: 5,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        voucher_gift_name:
          this.state.voucher_name !== ""
            ? this.state.voucher_name
            : cookie.load("UserFname"),
        voucher_gift_mobile:
          this.state.voucher_mobile !== "" ? this.state.voucher_mobile : "",
        voucher_gift_email:
          this.state.voucher_email !== ""
            ? this.state.voucher_email
            : cookie.load("UserEmail"),
        voucher_gift_message:
          this.state.voucher_message !== "" ? this.state.voucher_message : "",
        voucher_for: "Gift", 
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };

      Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
        (res) => {
          $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
          hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucher_name: "",
              voucher_mobile: "",
              voucher_email: "",
              voucher_message: "",
            });
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            this.handleShowAlertFun("Error", errMsgtxt);
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucherIndexFlag: "",
            });
          }

          return false;
        }
      );
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var productSlug = productDetail.product_slug;
    if (productSlug !== "") {
      $("#proIndex-" + productDetail.product_primary_id).addClass("active");
      this.props.sateValChange("view_pro_data", productSlug);
    }
    return false;
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    this.setState({ ["error_" + item]: "" });

  };

  addClassInput(){
    $(".input-focus").focus(function () {
      $(this).parents(".focus-out").addClass("focused");
    });
    $(".input-focus").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this).parents(".focus-out").removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
  }


  render() {
    return (
      <div className="productlist-main-div">
        <div className="innerproduct_row"> {this.state.product_List}</div>

        <div
          id="vouchergift-popup"
          className="mfp-hide popup_sec vouchergift-popup"
        >
          <div className="pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>IM PURCHASING THIS</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body product_voucher_body">
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section">
                    <div className="mainacc_mobrow">
                      <div className="tab_sec main_tabsec_inner">
                        <div className="myacc_filter">
                          <div className="tab_sec filter_tabsec">
                            <ul className="nav nav-tabs text-center product_voucher">
                              <li className="active">
                                <a data-toggle="tab"
                                  href="#tab-id-inn5"
                                  aria-expanded="true" >
                                  <span>For Gift</span>
                                </a>
                              </li>
                              <li className="">
                                <a
                                  data-toggle="tab"
                                  href="#tab-id-inn6"
                                  aria-expanded="false">
                                  <span>For Me</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div
                                id="tab-id-inn5"
                                className="tab-pane fade active in"
                              >
                                <h4 className="tab_mobtrigger inner_tab_border active">
                                <i></i>
                                </h4>
                                <div className="tab_mobrow filter_tabin">
                                  <div className="order-delivery">
                                    <div className="ord-body">
                                      <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                                        <ul className="myacc_order_details">
                                        <div className="voucher-popup-inner">
                                          <h2>GIFT RECEIVER'S INFORMATION</h2>
                                          <div className="form-group">
                                            <div className="focus-out">
                                              <label>Name</label>
                                              <input
                                                type="text"
                                                id="guest-name"
                                                name="guest-name"
                                                value={this.state.voucher_name}
                                                 onClick={this.addClassInput.bind(this)}
                                                 onChange={this.handleChangeTxt.bind(
                                                  this,
                                                  "voucher_name"
                                                )}
                                                className="form-control input-focus"
                                              />
                                              {this.state.error_voucher_name}
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="focus-out">
                                              <label>Enter Mobile Number</label>
                                              <input
                                                type="tel"
                                                id="guest-number"
                                                name="guest-number"
                                                value={this.state.voucher_mobile}
                                                onClick={this.addClassInput.bind(this)}
                                                onChange={this.handleChangeTxt.bind(
                                                  this,
                                                  "voucher_mobile"
                                                )}
                                                className="form-control input-focus"
                                              />
                                              {this.state.error_voucher_mobile}
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="focus-out">
                                              <label>Email Address</label>
                                              <input
                                                type="text"
                                                id="guest-email"
                                                name="guest-email"
                                                value={this.state.voucher_email}
                                                onClick={this.addClassInput.bind(this)}
                                                onChange={this.handleChangeTxt.bind(
                                                  this,
                                                  "voucher_email"
                                                )}
                                                className="form-control input-focus"
                                              />
                                              {this.state.error_voucher_email}
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            <div className="focus-out">
                                              <label>Message</label>
                                              <textarea
                                                type="text"
                                                id="guest-message"
                                                name="guest-message"
                                                value={this.state.voucher_message}
                                                onClick={this.addClassInput.bind(this)}
                                                onChange={this.handleChangeTxt.bind(
                                                  this,
                                                  "voucher_message"
                                                )}
                                                className="form-control input-focus"
                                              />
                                              {this.state.error_voucher_message}
                                            </div>
                                          </div>

                                          <div className="howtouse-gift-vouchar">
                                            <img src={smartPhone} />
                                            <h3>How to use</h3>
                                            <p>
                                            After purchasing, your recipient will get email notification to use the above email address to sign up. Vouchers will be available within their account.
                                            </p>
                                          </div>
                                          <div className="form-group gift-group">
                                            <button
                                              type="button"
                                              className="button"
                                              title = "Add to cart"
                                              onClick={this.addToCartVoucher.bind(this)}
                                            >
                                              Add To Cart
                                            </button>
                                          </div>
                                        </div>

                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div id="tab-id-inn6" className="tab-pane fade">
                                <h4 className="tab_mobtrigger inner_tab_border">
                                <i></i>
                                </h4>
                                <div className="tab_mobrow filter_tabin">
                                  <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                                    <ul className="myacc_order_details used-voucher">
                                     <div className="voucher-popup-inner">
                                      <div className="howtouse-gift-vouchar">
                                        <img src={smartPhone} />
                                        <h3>How to use</h3>
                                        <p>
                                        After purchasing, your recipient will get email notification to use the above email address to sign up. Vouchers will be available within their account.
                                        </p>
                                      </div>

                                      <button
                                        type="button"
                                        className="button"
                                        onClick={this.addToCartVoucherMe.bind(this)}
                                      >
                                        Add To Cart
                                      </button>
                                    </div>                                   
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  var cartItems = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cartItems = resultSetArr.cart_items;
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
    cartItems: cartItems,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },

    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
