/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from 'axios';
import cookie from "react-cookies";
import { appId, apiUrl } from "../Helpers/Config";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const phonenumberPattern = /^[0-9]{6,8}$/;
const isMobile = (mobile) =>
    mobile.match(phonenumberPattern) ? null : 'please enter valid Phone number.'

function validationConfig(props) {
  const { mobile, pin } = props.fields;

  return {
    fields: ["mobile", "pin"],

    validations: {
      mobile: [[isEmpty, mobile], [isMobile, mobile]],
      pin: [[isEmpty, pin]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile:'',
      changePinmsg: ''
    }
  }

  componentDidMount() {
      var mobile = this.props.fields.mobile;
      this.setState({'mobile': mobile});
  }

  componentWillReceiveProps(nextProps) {
      this.setState({'mobile': nextProps.fields.mobile});
  }

  isGuestPhonenumerExist(){
      const re = /^[0-9\b]+$/;
      var mobile = $("#mobile").val();  
      this.setState({'mobile' : mobile });
      this.props.onChange('mobile',mobile);
      if(mobile.length > 7){
          axios.get(apiUrl + "guestaccount/guest_customer_phone?app_id="+appId+"&status=A&phone_no="+mobile).then(response => {
              if (response.data.status === "ok") {
                  this.setState({changePinmsg: 'Existing users, Please click on Forgot Password and key in the email id, linked to the old website.'});
                  this.props.onChange('mobile',mobile);

              }else{
                  this.props.onChange('mobile',mobile);
                   this.setState({changePinmsg: ''});
              }
          });
      }else{
          this.setState({changePinmsg: ''});
          this.setState({'mobile': mobile});
      }
  }

  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;
    let errMsgEmail, errMsgPin;

    if ($validation.mobile.error.reason !== undefined) {
      errMsgEmail = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }
    if ($validation.pin.error.reason !== undefined) {
      errMsgPin = $validation.pin.show && (
        <span className="error">{$validation.pin.error.reason}</span>
      );
    }



    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Mobile Number</label>
            <input
              type="tel" 
              maxLength="8"
              id="mobile"
              onKeyPress={(e) => this.validateIntval(e)}
              className="form-control input-focus"
              value={this.state.mobile}
              onChange ={()=>this.isGuestPhonenumerExist()}
            />
             <p>{this.state.changePinmsg}</p>
            {errMsgEmail}
          </div>
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Your Password</label>

            <input
              type="password"
              className="form-control input-focus"
              value={fields.pin}
              {...$field("pin", (e) => onChange("pin", e.target.value))}
            />
            {errMsgPin}
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="button btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
        </div>

        <div className="form-group">
         <div className="controls two-links">
            <a
              href="#signup-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link"
            >
              Create an account
            </a>
            <span className="spilter"> </span>
            <a href="#forgot-password-popup"  data-effect="mfp-zoom-in" className="open-popup-link">
              Forgot Password
            </a>
          </div>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
