/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../Helpers/SettingHelper";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import iconcategory from "../../common/images/icon-category.png";
import icondarkcategory from "../../common/images/icon-category-dark.png";

class MenuNavigationMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigateMenu: []
    };
  }


  goCategoryProducts(selectedNavigation) {

    $(".menu-list-intvl active").removeClass("active");
    $("html, body").animate(
      {
        scrollTop: $("#pro_cat_" + selectedNavigation).offset().top - 200,
      },
      1000
    );
    $(".more-menu-parent, .more_categor_info").removeClass("active");
    $(".more_categor_info").hide();
  }

  navigateMenuList() {
    let navigateMenuArr = this.props.navigateMenu;
    let navigateCommon = this.props.navigateCommon;

    var selectedNav = this.props.productState.selectedNavigation;
    var selectedNavigationFilter =
      this.props.productState.selectedNavigationFilter;

    var selectedMainCatId = this.props.productState.selectedMainCatId;

    if (Object.keys(navigateMenuArr).length > 0) {
      const mainMenu = navigateMenuArr.map((loaddata, index) => {
          if (loaddata.menu_type == "main") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNavigationFilter !== ""
                    ? selectedNavigationFilter === loaddata.pro_cate_slug
                      ? "menu-list-intvl active"
                      : "menu-list-intvl"
                    : selectedNav === loaddata.pro_cate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  //to={"/products/category/" + loaddata.pro_cate_slug}
                  to={"/#" + loaddata.pro_cate_slug}
                  id={"menuNav_" + loaddata.pro_cate_slug}

                   onClick={(e) => {
                    e.preventDefault();
                    this.goCategoryProducts(loaddata.pro_cate_slug);
                  }}

                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
        
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </div>
            );
          } else if (loaddata.menu_type == "sub") {
            return (
              <div
                key={index + 1}
                className={
                  selectedNav === loaddata.pro_subcate_slug
                    ? "menu-list-intvl active"
                    : "menu-list-intvl"
                }
              >
                <Link
                  to={"/"}
                  className="menu-title-link"
                  title={loaddata.menu_custom_title}
                >
                  {stripslashes(loaddata.menu_custom_title.toLowerCase())}
                </Link>
              </div>
            );
          }
      });

      return mainMenu;
    } else {
      return;
    }
  }
  render() {
    var settingsTestimonial = {
      items: 6,
      autoplay: false,
      margin: 0,
      nav: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
        500: {
          items: 2,
        },
        900: {
          items: 4,
        },
        1200: {
          items: 6,
        },
      },
    };
    return (
      <>
        <div className="owlmobile">
          <div className="container">
            {Object.keys(this.props.navigateMenu).length > 0 && (
              <OwlCarousel options={settingsTestimonial}>
                {this.navigateMenuList()}
              </OwlCarousel>
            )}
          </div> 
        </div> 
      </>
    );
  }
}

export default MenuNavigationMobile;
